.garderobo-widget-container-for-popup {
  display: flex !important;
}
.garderobo-widget-look__bottom-panel {
  height: 50px;
  position: absolute;
  background: #fff;
  bottom: 0;
  width: 50%;
  border-top: 1px solid #e0e0e0;
  display: flex;
  flex-direction: row;
  padding: 4px;
  gap: 10px;
}
.garderobo-widget-look__bottom-panel > div {
  display: flex;
  flex-direction: column;
}
.garderobo-widget-look__bottom-panel div p {
  margin: 0;
}
.garderobo-widget-look__bottom-panel .garderobo-widget-popup-list-item-text-cart-btn {
  width: 200px;
  margin: 0;
}
.garderobo-widget-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.garderobo-widget-container .garderobo-widget-feed {
  position: relative;
  z-index: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.garderobo-widget-container .garderobo-widget-feed header {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container,
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new {
  position: relative;
  display: flex;
  overflow: hidden;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container .garderobo-widget-feed-main-item-container,
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-main-item-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container .garderobo-widget-feed-items,
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  left: 0;
}
@media screen and (min-width: 768px) {
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container .garderobo-widget-feed-items.garderobo-widget-feed-less-than-per-page,
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items.garderobo-widget-feed-less-than-per-page {
    justify-content: center;
  }
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container--mobile,
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new--mobile {
  width: 100%;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container--mobile .garderobo-widget-feed-items,
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new--mobile .garderobo-widget-feed-items,
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container--mobile .garderobo-widget-feed-items.garderobo-widget-feed-less-than-per-page,
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new--mobile .garderobo-widget-feed-items.garderobo-widget-feed-less-than-per-page {
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (min-width: 768px) {
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container--mobile .garderobo-widget-feed-items,
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new--mobile .garderobo-widget-feed-items,
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container--mobile .garderobo-widget-feed-items.garderobo-widget-feed-less-than-per-page,
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new--mobile .garderobo-widget-feed-items.garderobo-widget-feed-less-than-per-page {
    justify-content: flex-start;
  }
}
@media screen and (min-width: 1024px) {
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container--mobile .garderobo-widget-feed-items,
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new--mobile .garderobo-widget-feed-items,
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container--mobile .garderobo-widget-feed-items.garderobo-widget-feed-less-than-per-page,
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new--mobile .garderobo-widget-feed-items.garderobo-widget-feed-less-than-per-page {
    flex-wrap: nowrap;
  }
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container--mobile .garderobo-widget-feed-item,
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new--mobile .garderobo-widget-feed-item {
  width: calc(50% - 20px);
  margin: 0 20px 20px 0;
}
@media screen and (min-width: 768px) {
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container--mobile .garderobo-widget-feed-item,
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new--mobile .garderobo-widget-feed-item {
    width: calc(33.33% - 20px);
  }
}
@media screen and (min-width: 1024px) {
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container--mobile .garderobo-widget-feed-item,
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new--mobile .garderobo-widget-feed-item {
    width: 15.3%;
    margin: 0 10px;
  }
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new {
  gap: 15px;
  width: 1000px;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items {
  flex: 1 1;
  flex-direction: column;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items .garderobo-widget-product-image {
  height: 180px;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items .garderobo-widget-feed-item {
  align-items: center;
  cursor: default;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items .garderobo-widget-feed-item .garderobo-widget-product-footer {
  justify-content: flex-start !important;
  max-width: 300px;
  height: auto !important;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items .garderobo-widget-feed-item .garderobo-widget-product-footer .garderobo-widget-sizes {
  border: 1px solid #ccc;
  box-shadow: inset 0 0 3px #e8e8e8;
  color: #333;
  padding: 5px;
  outline: none;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items .garderobo-widget-feed-item .garderobo-widget-product-footer .garderobo-widget-sizes:hover {
  border: 1px solid black;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items .garderobo-widget-feed-item .garderobo-widget-product-footer .garderobo-widget-brand-and-price {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items .garderobo-widget-feed-item .garderobo-widget-product-footer .garderobo-widget-brand-name,
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items .garderobo-widget-feed-item .garderobo-widget-product-footer .garderobo-widget-product-price-container {
  flex: 1 1;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items .garderobo-widget-feed-item .garderobo-widget-product-footer .garderobo-widget-brand-name {
  font-size: 24px;
  text-transform: none;
  text-align: left;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items .garderobo-widget-feed-item .garderobo-widget-product-footer .garderobo-widget-product-price-container .garderobo-widget-product-price {
  font-size: 19px;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items .garderobo-widget-feed-item .garderobo-widget-product-footer .garderobo-widget-product-name {
  width: 100%;
  text-align: left;
  color: #333;
  font-size: 14px;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items .garderobo-widget-feed-item .garderobo-widget-product-footer .garderobo-widget-sizes-and-cart-btn {
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: default;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-add-to-cart-btn {
  background-color: #333;
  border: 1px solid #333;
  color: white;
  text-decoration: none;
  padding: 5px;
  cursor: pointer;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-add-to-cart-btn.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: default;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-add-to-cart-btn:hover {
  background-color: #fff;
  color: #333;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-add-all-product-wrap {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  align-items: center;
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-main-item-name {
  font-weight: 500;
  font-size: 19px;
  text-align: center;
  margin: 4px 0 20px;
  color: #333;
}
@media screen and (max-width: 767px) {
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new {
    flex-direction: column;
  }
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-sizes-and-cart-btn {
    flex-direction: column;
    gap: 10px;
  }
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items .garderobo-widget-feed-item {
    flex-flow: row;
  }
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items .garderobo-widget-feed-item .garderobo-widget-product-footer {
    height: 100% !important;
    justify-content: center !important;
  }
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items .garderobo-widget-feed-item .garderobo-widget-product-footer .garderobo-widget-brand-and-price {
    flex-direction: column;
    gap: 5px;
  }
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items .garderobo-widget-feed-item .garderobo-widget-product-footer .garderobo-widget-brand-and-price .garderobo-widget-product-price-container {
    justify-content: flex-start;
    flex-direction: row;
  }
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items .garderobo-widget-feed-item .garderobo-widget-product-footer .garderobo-widget-brand-and-price .garderobo-widget-product-price-container .garderobo-widget-product-price {
    margin-left: 0;
    padding-left: 0;
  }
  .garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container-new .garderobo-widget-feed-items .garderobo-widget-feed-item .garderobo-widget-product-footer .garderobo-widget-product-name {
    padding-top: 0;
  }
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-control-left,
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-control-right {
  position: absolute;
  top: calc(50% - 24px);
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  z-index: 100;
}
.garderobo-widget-container .garderobo-widget-feed-item {
  flex-shrink: 0;
}
.garderobo-widget-container .garderobo-widget-feed-item_inner {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.garderobo-widget-container .garderobo-widget-feed-item_inner .garderobo-widget-product-header {
  width: 100%;
}
.garderobo-widget-container .garderobo-widget-feed-item_inner .garderobo-widget-product-image {
  display: block;
  background-size: cover;
  background-position: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.garderobo-widget-container .garderobo-widget-feed-item_inner .garderobo-widget-product-image--button {
  cursor: pointer;
}
.garderobo-widget-container .garderobo-widget-feed-item_inner .garderobo-widget-product-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.garderobo-widget-container .garderobo-widget-feed-item_inner .garderobo-widget-product-footer .garderobo-widget-product-name {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}
.garderobo-widget-container .garderobo-widget-feed-item_inner .garderobo-widget-product-footer .garderobo-widget-product-price,
.garderobo-widget-container .garderobo-widget-feed-item_inner .garderobo-widget-product-footer .garderobo-widget-product-old-price {
  white-space: nowrap;
}
.garderobo-widget-container .garderobo-widget-feed-item_inner .garderobo-widget-product-footer .garderobo-widget-product-control-dislike,
.garderobo-widget-container .garderobo-widget-feed-item_inner .garderobo-widget-product-footer .garderobo-widget-product-control-dislike {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
button {
  border: 0;
  background-color: transparent;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
}
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.garderobo-widget-sizes-list,
.garderobo-widget-add-to-cart-no-size-btn,
.garderobo-widget-brand-name,
.garderobo-widget-product-sale-badge {
  display: none;
}
.garderobo-widget-muted {
  display: none !important;
}
.garderobo-widget-displayed-none,
.garderobo-widget-hidden {
  display: none !important;
}
.garderobo-widget-look-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 10px;
  cursor: pointer;
}
.garderobo-widget-feed-item--special .garderobo-widget-look-container {
  border: 1px solid #ccc;
  border-radius: 9px;
  padding: 5px;
}
.garderobo-widget-look-product {
  position: absolute;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}
.garderobo-widget-look-container--template-default .garderobo-widget-look-product--wear,
.garderobo-widget-look-container--template-default .garderobo-widget-look-product--top-2-no-wear {
  z-index: 2;
  width: 35%;
  top: 0;
  left: 55%;
  bottom: auto;
  right: auto;
}
.garderobo-widget-look-container--template-default .garderobo-widget-look-product--wear {
  height: 60%;
}
.garderobo-widget-look-container--template-default .garderobo-widget-look-product--top-2-no-wear {
  height: 45%;
}
.garderobo-widget-look-container--template-default .garderobo-widget-look-product--top {
  z-index: 5;
  width: 35%;
  height: 45%;
  top: 0;
  right: 50%;
  bottom: auto;
  left: auto;
  background-position-x: right;
}
.garderobo-widget-look-container--template-default .garderobo-widget-look-product--bottom {
  z-index: 4;
  left: 40%;
  width: 25%;
  height: 65%;
  bottom: 0;
  top: auto;
  right: auto;
  background-position-x: center;
}
.garderobo-widget-look-container--template-default .garderobo-widget-look-product--shoes {
  z-index: 6;
  width: 30%;
  height: 40%;
  right: 10px;
  bottom: 0;
  top: auto;
  left: auto;
  background-position: bottom;
}
.garderobo-widget-look-container--template-default .garderobo-widget-look-product--accessory {
  z-index: 1;
  width: 20%;
  height: 40%;
  left: 30px;
  bottom: 0;
  top: auto;
  right: auto;
  background-position-y: bottom;
  background-position-x: right;
}
.garderobo-widget-look-container--template-default .garderobo-widget-look-product--vest {
  width: 25%;
  height: 50%;
  left: 55%;
  top: auto;
  bottom: auto;
  right: auto;
}
.garderobo-widget-look-product--left-top {
  z-index: 1;
  left: 15%;
  width: 27%;
  height: 40%;
  background-position: center;
}
.garderobo-widget-look-product--layer-full {
  z-index: 1;
  left: 65%;
  width: 25%;
  height: 50%;
  top: 20%;
  background-position-y: center;
}
.garderobo-widget-look-product--right-top {
  z-index: 1;
  right: 15%;
  width: 27%;
  height: 40%;
  background-position: center;
}
.garderobo-widget-look-product--center {
  z-index: 5 !important;
  left: 35%;
  width: 30%;
  height: 38%;
  top: 20%;
  background-position-x: center;
}
.garderobo-widget-look-product--right-bottom {
  z-index: 1;
  left: 55%;
  bottom: 2%;
  width: 23%;
  height: 50%;
  background-position-x: left;
}
.garderobo-widget-look-product--shoes {
  z-index: 4;
  left: 10%;
  bottom: 2%;
  width: 20%;
  height: 30%;
  background-position-y: bottom;
}
.garderobo-widget-look-product--bag {
  z-index: 4;
  width: 24%;
  height: 26%;
  background-position-x: center;
}
.garderobo-widget-look-product--wallet-bag {
  z-index: 4;
  width: 13%;
  height: 26%;
  background-position-x: center;
  background-position-y: center;
}
.garderobo-widget-look-product--belt {
  z-index: 1;
  width: 18%;
  height: 15%;
  left: 15%;
  top: 44%;
  background-position-x: center;
}
.garderobo-widget-look-product--belt-2 {
  top: 53%;
}
.garderobo-widget-look-product--belt-3 {
  top: 35%;
}
.garderobo-widget-look-product--right-belt {
  right: 16%;
  top: 53%;
  left: auto;
}
.garderobo-widget-look-product--hat {
  z-index: 5;
  left: 52%;
  width: 20%;
  height: 16%;
  background-position-x: center;
}
.garderobo-widget-look-product--bottle {
  z-index: 5;
  width: 10%;
  bottom: 30%;
  left: 20%;
  height: 25%;
  background-position-y: bottom;
}
.garderobo-widget-look-product--socks {
  z-index: 1;
  width: 20%;
  bottom: 10%;
  left: 6%;
  height: 20%;
  background-position-y: bottom;
  display: none;
}
.garderobo-widget-look-product--wear {
  height: 50%;
  width: 27%;
  z-index: 2;
}
.garderobo-widget-look-product--right-top.garderobo-widget-look-product--layer-3_vest,
.garderobo-widget-look-product--right-top.garderobo-widget-look-product--layer-2_vest {
  height: 35%;
}
.garderobo-widget-look-product--left-top.garderobo-widget-look-product--layer-3_vest,
.garderobo-widget-look-product--left-top.garderobo-widget-look-product--layer-2_vest {
  height: 35%;
}
.garderobo-widget-look-product--center.garderobo-widget-look-product--layer-3_vest,
.garderobo-widget-look-product--center.garderobo-widget-look-product--layer-2_vest {
  height: 40%;
}
.garderobo-widget-look-product--left-top.garderobo-widget-look-product--layer-1_top,
.garderobo-widget-look-product--right-top.garderobo-widget-look-product--layer-1_top {
  width: 20%;
}
.garderobo-widget-look-product--center.garderobo-widget-look-product--layer-1_top {
  width: 25%;
  left: 36%;
}
.garderobo-widget-look-product--left-top.garderobo-widget-look-product--layer-1_ushirt,
.garderobo-widget-look-product--right-top.garderobo-widget-look-product--layer-1_ushirt {
  height: 33%;
}
.garderobo-widget-look-product--center.garderobo-widget-look-product--layer-1_ushirt,
.garderobo-widget-look-product--center.garderobo-widget-look-product--layer-1_ushirt {
  height: 30%;
}
.garderobo-widget-look-product--left-top-2 {
  left: 19%;
}
.garderobo-widget-look-product--right-top-2 {
  right: 19%;
}
.garderobo-widget-look-product--right-top-3 {
  right: 13%;
  top: 24%;
}
.garderobo-widget-look-product--left-hat {
  left: 27%;
}
.garderobo-widget-look-product--center-hat {
  left: 40%;
}
.garderobo-widget-look-product--center-shorts {
  top: 35%;
  height: 35%;
  width: 25%;
}
.garderobo-widget-look-product--right-top-bag {
  right: 10%;
  top: 20%;
}
.garderobo-widget-look-product--left-center-bag {
  top: 50%;
  left: 7%;
}
.garderobo-widget-look-product--right-center-bag {
  bottom: 28%;
  right: 16%;
}
.garderobo-widget-look-product--left-top-bag {
  left: 15%;
}
.garderobo-widget-look-product--right-bottom-bag {
  right: 12%;
  bottom: 3%;
  background-position-y: bottom;
}
.garderobo-widget-look-product--right-top-wallet-bag {
  right: 23%;
  top: 20%;
}
.garderobo-widget-look-product--left-center-wallet-bag {
  top: 50%;
  left: 22%;
}
.garderobo-widget-look-product--right-center-wallet-bag {
  bottom: 28%;
  right: 25%;
}
.garderobo-widget-look-product--left-top-wallet-bag {
  left: 28%;
}
.garderobo-widget-look-product--right-bottom-wallet-bag {
  right: 25%;
  bottom: 3%;
  background-position-y: bottom;
}
.garderobo-widget-look-product--gloves {
  z-index: 1;
  width: 15%;
  height: 15%;
  background-position-x: center;
}
.garderobo-widget-look-product--right-gloves {
  right: 5%;
  top: 47%;
}
.garderobo-widget-look-product--right-gloves-2 {
  right: 5%;
  top: 53%;
}
.garderobo-widget-look-product--left-gloves {
  left: 13%;
  top: 19%;
}
.garderobo-widget-look-product--left-gloves-2 {
  left: 19%;
  top: 19%;
}
.garderobo-widget-look-product--left-gloves-3 {
  left: 3%;
  top: 3%;
}
.garderobo-widget-look-product--left-gloves-4 {
  left: 20%;
  top: 35%;
}
.garderobo-widget-look-product--left-gloves-5 {
  left: 20%;
  top: 60%;
}
.garderobo-widget-look-product--bottom-center-shoes {
  left: 27%;
}
.garderobo-widget-look-product--bottom-center-shoes-2 {
  left: 36%;
}
.garderobo-widget-look-product--right-bottom-shoes {
  right: 13%;
  left: auto;
}
.garderobo-widget-look-product--center-layer-full {
  height: 60%;
}
.garderobo-widget-look-product--bottom-left-socks {
  bottom: 3%;
  left: 14%;
}
.garderobo-widget-look-product--bottom-right-socks {
  bottom: 3%;
  right: 18%;
  left: auto;
}
.garderobo-widget-look-product--socks-2 {
  bottom: 29%;
  right: 18%;
  left: auto;
}
.garderobo-widget-look-product--center-bottom {
  top: 35%;
  height: 60%;
  width: 22%;
  background-position-x: center;
}
.garderobo-widget-look-product--center.garderobo-widget-look-product--center-bottom {
  left: 37%;
}
.garderobo-widget-look-product--center-layer-1 {
  top: 25%;
}
.garderobo-widget-look__btn-buy {
  position: absolute;
  bottom: -52px;
  left: 50%;
  transform: translate(-50%);
  border: 1px solid #333;
  padding: 8px 16px;
  text-transform: uppercase;
  font-weight: bold;
  color: #333;
}
.garderobo-widget-look__btn-buy:hover {
  background-color: #d1d0d0;
}
.garderobo-widget-look__badge-special {
  background-color: #ae946d;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  position: absolute;
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  z-index: 10;
  opacity: 0.8;
}
.garderobo-widget-feed-item-look .garderobo-widget-feed-item {
  position: relative;
  padding-bottom: 100% !important;
  margin-bottom: 70px !important;
  border: none !important;
  width: 100% !important;
}
@media screen and (min-width: 425px) {
  .garderobo-widget-feed-item-look .garderobo-widget-feed-item {
    width: 100% !important;
    padding-bottom: 100% !important;
  }
}
@media screen and (min-width: 550px) {
  .garderobo-widget-feed-item-look .garderobo-widget-feed-item {
    width: 50% !important;
    padding-bottom: 50% !important;
  }
}
@media screen and (min-width: 768px) {
  .garderobo-widget-feed-item-look .garderobo-widget-feed-item {
    width: 50% !important;
    padding-bottom: 50% !important;
  }
}
@media screen and (min-width: 992px) {
  .garderobo-widget-feed-item-look .garderobo-widget-feed-item {
    width: 50% !important;
    padding-bottom: 50% !important;
  }
}
@media screen and (min-width: 1024px) {
  .garderobo-widget-feed-item-look .garderobo-widget-feed-item {
    width: 33.33% !important;
    padding-bottom: 33.33% !important;
  }
}
@media screen and (min-width: 1140px) {
  .garderobo-widget-feed-item-look .garderobo-widget-feed-item {
    width: 33.33% !important;
    padding-bottom: 33.33% !important;
  }
}
.garderobo-widget-container .garderobo-widget-feed .garderobo-widget-feed-container.garderobo-widget-feed-item-look .garderobo-widget-feed-items .garderobo-widget-feed-item {
  margin-left: 0;
  margin-right: 0;
}
.garderobo-widget-feed-header--with-counter {
  display: flex;
  justify-content: space-between;
}
.garderobo-widget-feed-items-counter {
  display: flex;
  align-items: center;
  font-size: 20px;
}
.garderobo-widget-feed-items-counter-btn {
  width: 30px;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.garderobo-widget-feed-items-counter-btn:disabled {
  opacity: 0.5;
}
.garderobo-widget-feed-items-counter-btn::before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='icon--jumbotron-left-b' viewBox='0 0 28 28' width='100%25' height='100%25'%3E%3C!-- Generator: Sketch 3.8.2 (29753) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3ESlice 1%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='square'%3E%3Cg id='Next' transform='translate(14.000000, 14.000000) rotate(-180.000000) translate(-14.000000, -14.000000) translate(2.000000, 2.000000)' stroke-width='3' stroke='%2319181d'%3E%3Cg id='Group' transform='translate(0.000000, -0.000000)'%3E%3Cpolyline id='Line' transform='translate(12.000000, 12.000000) rotate(-315.000000) translate(-12.000000, -12.000000) ' points='4 4 20 4 20 20'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.garderobo-widget-feed-items-counter-btn--next::before {
  transform: rotate(180deg);
}
.garderobo-widget-popup-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
}
.garderobo-widget-popup-container--opened {
  display: flex;
}
.garderobo-widget-popup-container .garderobo-widget-feed-item-look .garderobo-widget-feed-item {
  width: 100% !important;
  padding-bottom: 100% !important;
}
.garderobo-widget-popup-container .garderobo-widget-look-container {
  cursor: default;
}
.garderobo-widget-popup-container .garderobo-widget-sizes {
  border: 1px solid #ccc;
  color: #333;
  padding: 5px;
  outline: none;
  margin-right: 5px;
}
.garderobo-widget-popup-container .garderobo-widget-sizes:hover {
  border-color: black;
}
.garderobo-widget-popup-container h3:before {
  content: unset !important;
}
.garderobo-widget-popup {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  overflow: auto;
}
.garderobo-widget-popup .garderobo-widget-displayed-none {
  display: block !important;
}
.garderobo-widget-popup__btn-close {
  color: #000;
  right: 15px;
  top: 15px;
  position: absolute;
  font-size: 26px;
  line-height: 15px;
  z-index: 100;
  padding: 15px;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}
.garderobo-widget-popup__btn-close::after,
.garderobo-widget-popup__btn-close::before {
  content: '';
  width: 2px;
  height: 100%;
  background-color: #333;
  position: absolute;
  top: 0;
  left: 50%;
}
.garderobo-widget-popup__btn-close::before {
  transform: rotate(45deg);
}
.garderobo-widget-popup__btn-close::after {
  transform: rotate(-45deg);
}
.garderobo-widget-popup__btn-close:hover {
  transform: scale(1.1);
}
.garderobo-widget-popup-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border: 1px solid #d7d7d7;
  padding-top: 0;
}
.garderobo-widget-container .garderobo-widget-popup-collage-container .garderobo-widget-feed .garderobo-widget-control-left {
  left: -15px;
}
.garderobo-widget-container .garderobo-widget-popup-collage-container .garderobo-widget-feed .garderobo-widget-control-right {
  right: -15px;
}
.garderobo-widget-popup-collage-container {
  border: none;
  border-right: 1px solid #d7d7d7;
  width: 100%;
  padding-top: 60px;
}
.garderobo-widget-popup-collage-container .garderobo-widget-feed {
  margin: 0 20px !important;
}
.garderobo-widget-popup-collage-container .garderobo-widget-feed header {
  font-size: 20px !important;
}
.garderobo-widget-popup-collage-container .garderobo-widget-look__btn-buy {
  display: none;
}
.garderobo-widget-popup-list-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
}
.garderobo-widget-popup-collage-container__title {
  font-size: 18px;
}
.garderobo-widget-popup-list-header {
  height: 0;
  padding: 0 25px 0 55px;
  border-bottom: 1px solid #d7d7d7;
}
.garderobo-widget-popup-list-footer {
  display: none;
}
.garderobo-widget-popup-list-content {
  margin: 0px;
  padding: 0px;
  list-style: none;
  height: calc(100% - 1px);
  overflow: auto;
}
.garderobo-widget-popup-list-item {
  position: relative;
  margin: 0;
  flex-flow: row nowrap;
  display: flex;
  padding: 18px 0;
  width: 100%;
  border-top: 1px solid #d7d7d7;
}
.garderobo-widget-popup-list-item:first-child {
  border-top: none;
}
.garderobo-widget-popup-list-item-pic {
  width: 18%;
  height: auto;
  background: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  position: relative;
}
.garderobo-widget-popup-list-item-img-wrapper {
  width: 100%;
  height: 70px;
  position: relative;
}
.garderobo-widget-popup-list-item-img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.garderobo-widget-popup-list-item-text {
  width: 56%;
  margin-right: 3%;
  text-align: left;
  margin-left: 20px;
  overflow: hidden;
}
.garderobo-widget-popup-list-item-text-brand {
  margin: 0 0 10px 0;
  display: none;
}
.garderobo-widget-popup-list-item-text-title {
  text-transform: none;
  text-align: left;
  line-height: 1.2;
  margin: 10px 0 0 0;
  overflow: hidden;
  font-size: 16px;
  white-space: pre-wrap;
  display: block;
  width: 100%;
}
.garderobo-widget-popup-list-item-text-title a {
  text-decoration: none;
  color: #000;
}
.garderobo-widget-popup-list-item-text-title a:hover {
  text-decoration: underline;
}
.garderobo-widget-popup-list-item-text-bottom {
  margin: 10px 0 0 0;
  display: flex;
  align-items: flex-end;
  text-align: left;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.garderobo-widget-popup-list-item-text-prices {
  display: flex;
  padding-top: 4px;
  grid-gap: 5px;
  margin-bottom: 5px;
}
.garderobo-widget-popup-list-item-text-discount,
.garderobo-widget-popup-list-item-text-new-price,
.garderobo-widget-popup-list-item-text-price {
  display: inline-block;
  min-width: 25%;
  max-width: unset;
  white-space: nowrap;
}
.garderobo-widget-popup-list-item-text-new-price {
  color: #ff3333;
  margin-right: 10px;
}
.garderobo-widget-popup-list-item-text-discount {
  text-decoration: line-through;
}
.garderobo-widget-popup-list-item-text-cart-btn {
  text-transform: uppercase;
  background-color: white;
  border: 2px solid black;
  padding: 10px;
  font-size: 12px;
  font-weight: bold;
  min-width: 100px;
  margin-top: 5px;
  text-align: center;
  color: inherit;
  text-decoration: none;
}
.garderobo-widget-popup-list-item-text-cart-btn--disabled {
  opacity: 0.4;
}
.garderobo-widget-popup-list-item-text-error {
  width: 100%;
  text-align: left;
  color: #ff3333;
  font: 12px 'FFDIN Regular', 'FFDIN', Arial, sans-serif;
  margin-top: 5px;
}
.garderobo-widget-popup-list-item-swap {
  padding: 3px;
  display: block;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: underline;
  position: static;
  background: none;
  font-size: 10px;
  cursor: pointer;
}
.garderobo-widget-popup-list-item-swap-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: auto;
  right: 0;
  z-index: 999;
}
.garderobo-widget-popup-list-item-swap-container--opened {
  display: block;
}
.garderobo-widget-popup-list-item-swap-container--hidden {
  display: none;
}
.garderobo-widget-popup-list-item-swap-container-list {
  list-style: none;
  padding: 0;
  display: flex;
  background-color: white;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
.garderobo-widget-popup-list-item-swap-container-item {
  position: relative;
  padding: 12px 5px;
  height: 107.5px;
  cursor: pointer;
}
.garderobo-widget-popup-list-item-swap-container-item:first-child {
  margin-left: 10px;
}
.garderobo-widget-popup-list-item-swap-container-item--selected .garderobo-widget-popup-list-item-swap-item-content {
  border-right: 1px solid #dbdbdb;
  padding-right: 20px;
}
.garderobo-widget-popup-list-item-swap-container-item--selected .garderobo-widget-popup-list-item-swap-item-price {
  padding: 0 20px 0 8px;
}
.garderobo-widget-popup-list-item-swap-container-item .garderobo-widget-popup__btn-close {
  transform: scale(0.5);
  top: 5px;
  right: 5px;
}
.garderobo-widget-popup-list-item-swap-container-item .garderobo-widget-popup__btn-close:hover {
  transform: scale(0.6);
}
.garderobo-widget-popup-list-item-swap-item-content {
  position: relative;
  padding: 0 0.5em;
  border: 1px solid transparent;
  max-width: 100%;
  box-sizing: border-box;
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.garderobo-widget-popup-list-item-swap-item-img-wrapper {
  position: relative;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 70%;
}
.garderobo-widget-popup-list-item-swap-item-img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}
.garderobo-widget-popup-list-item-swap-item-price {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  text-decoration: underline;
  text-align: center;
  font-size: 14px;
  box-sizing: border-box;
}
.garderobo-widget-popup-list-item-swap-item-price--sale {
  color: #ff3333;
}
@media screen and (min-width: 425px) {
  .garderobo-widget-popup-list-item-text-error {
    text-align: left;
  }
}
@media screen and (min-width: 768px) {
  .garderobo-widget-popup {
    height: auto;
    overflow: hidden;
  }
  .garderobo-widget-popup-container--opened {
    display: flex;
  }
  .garderobo-widget-popup-collage-container .garderobo-widget-feed {
    margin: 0 50px !important;
  }
  .garderobo-widget-popup-list-header {
    height: 60px;
  }
  .garderobo-widget-popup-list-content {
    height: calc(100% - 60px - 1px);
  }
  .garderobo-widget-popup-content {
    flex-direction: row;
    height: 100%;
  }
  .garderobo-widget-popup-collage-container,
  .garderobo-widget-popup-list-container {
    width: 50%;
  }
}
@media screen and (min-width: 1024px) {
  .garderobo-widget-popup {
    width: 85%;
  }
}
@media screen and (min-width: 1368px) {
  .garderobo-widget-popup-list-item-img-wrapper {
    height: 120px;
  }
  .garderobo-widget-popup-list-item-swap-container-item {
    height: 157.5px;
  }
}
#btn-special-look,
.garderobo-scroll-button {
  font-family: inherit;
  background-color: #ae946d;
  color: #fff;
  border-radius: 50%;
  margin: 0;
  -webkit-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
  font-size: 12px;
  text-transform: uppercase;
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-top: 8px;
}
#btn-special-look:hover,
.garderobo-scroll-button:hover {
  background-color: #c0a680;
}
#btn-special-look:hover .garderobo-btn-special-look-arrow,
.garderobo-scroll-button:hover .garderobo-btn-special-look-arrow {
  transform: rotate(-45deg) translate(-3px, 3px);
}
#btn-special-look .garderobo-btn-special-look-arrow,
.garderobo-scroll-button .garderobo-btn-special-look-arrow {
  display: block;
  width: 7px;
  height: 7px;
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
  -webkit-transition: transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
}
.garderobo-widget-popup-2 {
  font-family: 'Nunito Sans', sans-serif;
  height: 100vh !important;
  --buy-look-popup-black: #181818;
  color: var(--buy-look-popup-black);
  max-width: 950px;
}
.garderobo-widget-popup-2 * {
  box-sizing: border-box;
}
.garderobo-widget-popup-2__content {
  padding: 45px 0 20px 0;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.garderobo-widget-popup-2__header {
  width: 100%;
  margin: 0 0 0 25px;
  text-transform: uppercase;
  height: 0px;
}
.garderobo-widget-popup-2__left-block {
  height: calc(100% - 0px);
  overflow: auto;
  display: none;
}
.garderobo-widget-popup-2__right-block {
  display: none;
  width: 100%;
  height: calc(100% - 0px);
}
.garderobo-widget-popup-2__right-block .garderobo-widget-popup-2__prices {
  margin-bottom: 20px;
}
.garderobo-widget-popup-2__right-block .garderobo-widget-popup-2__price,
.garderobo-widget-popup-2__right-block .garderobo-widget-popup-2__price-old {
  font-size: 18px;
  font-weight: 700;
}
.garderobo-widget-popup-2__right-blocks {
  height: calc(100% - 0px);
  overflow-y: auto;
}
.garderobo-widget-popup-2__right-blocks-item {
  border-bottom: 1px solid #eee;
  margin: 0 25px 20px;
}
.garderobo-widget-popup-2__right-blocks-item:last-child {
  border: none;
  margin-bottom: 0;
}
.garderobo-widget-popup-2__right-blocks-item:last-child .garderobo-widget-popup-2__right-btn-buy {
  margin-bottom: 0;
}
.garderobo-widget-popup-2__thumb-container {
  padding: 20px;
  width: 220px;
  border: 1px solid #eee;
  margin-bottom: 20px;
  cursor: pointer;
}
.garderobo-widget-popup-2__thumb-container:last-child {
  margin-bottom: 0;
}
.garderobo-widget-popup-2__thumb-container:hover,
.garderobo-widget-popup-2__thumb-container--selected {
  border-color: var(--buy-look-popup-black);
}
.garderobo-widget-popup-2__prices--discounted .garderobo-widget-popup-2__price {
  color: #ff3333;
}
.garderobo-widget-popup-2__price-old {
  margin-right: 10px;
  text-decoration: line-through;
}
.garderobo-widget-popup-2__thumb {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  object-fit: contain;
  padding: 0 30px;
  box-sizing: border-box;
  object-position: center;
}
.garderobo-widget-popup-2__thumb-title {
  margin-bottom: 5px;
}
.garderobo-widget-popup-2__right-top {
  display: flex;
}
.garderobo-widget-popup-2__right-thumb {
  width: 108px;
  height: 108px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex-shrink: 0;
  margin: 0 10px 20px 0;
}
.garderobo-widget-popup-2__right-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
}
.garderobo-widget-popup-2__right-sizes-select {
  font-family: 'Nunito Sans', sans-serif;
  padding: 9px;
  width: 150px;
}
.garderobo-widget-popup-2__right-sizes-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}
.garderobo-widget-popup-2__right-sizes {
  display: flex;
  grid-gap: 4px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.garderobo-widget-popup-2__right-size {
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
}
.garderobo-widget-popup-2__right-size:hover {
  border-color: var(--buy-look-popup-black);
}
.garderobo-widget-popup-2__right-size--selected {
  cursor: default;
  border-color: var(--buy-look-popup-black);
}
.garderobo-widget-popup-2__right-similars-wrapper {
  position: relative;
}
.garderobo-widget-popup-2__right-similars {
  margin-bottom: 20px;
  display: flex;
  grid-gap: 10px;
  overflow-x: auto;
}
.garderobo-widget-popup-2__right-sim {
  cursor: pointer;
  padding-bottom: 10px;
  flex-shrink: 0;
  width: calc((100% - 2 * 10px) / 3);
}
.garderobo-widget-popup-2__right-sim--selected {
  cursor: default;
}
.garderobo-widget-popup-2__right-sim--selected .garderobo-widget-popup-2__right-sim-pic {
  border-color: var(--buy-look-popup-black);
}
.garderobo-widget-popup-2__right-sim-pic {
  width: 100%;
  height: 120px;
  padding: 15px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
.garderobo-widget-popup-2__right-sim-pic-inner {
  background-size: contain;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.garderobo-widget-popup-2__right-sim-price {
  text-align: center;
}
.garderobo-widget-popup-2__right-sim-price .garderobo-widget-popup-2__price {
  font-weight: normal;
  font-size: 16px;
}
.garderobo-widget-popup-2__right-controls {
  display: flex;
}
.garderobo-widget-popup-2__right-btn-buy {
  text-transform: uppercase;
  color: #fff;
  background-color: #ae946d;
  padding: 10px 20px;
  margin-bottom: 40px;
  font-size: 16px;
  white-space: nowrap;
  width: 100%;
  max-width: 100%;
  text-align: center;
  text-decoration: none;
}
.garderobo-widget-popup-2__right-btn-buy:active {
  background-color: #b28748;
}
.garderobo-widget-popup-2__right-link {
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
  border-bottom: 2px solid #ccc;
  font-size: 12px;
}
.garderobo-widget-popup-2__mob-controls {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 15px;
}
.garderobo-widget-popup-2__mob-controls .garderobo-widget-popup-2__right-btn-buy {
  width: 100%;
  margin: 0;
}
.garderobo-widget-popup-2__arrow {
  height: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 20px);
  margin: 0 0 10px;
}
.garderobo-widget-popup-2__arrow--down {
  margin: 10px 0 0;
  transform: rotate(180deg);
}
.garderobo-widget-popup-2__arrow--left {
  position: absolute;
  left: -20px;
  width: 20px;
  height: 20px;
  margin: 0;
  top: 51px;
  transform: rotate(-90deg);
}
.garderobo-widget-popup-2__arrow--right {
  position: absolute;
  right: -20px;
  width: 20px;
  height: 20px;
  margin: 0;
  top: 51px;
  transform: rotate(90deg);
}
.garderobo-widget-popup-2__arrow:disabled {
  display: none;
}
.garderobo-widget-popup.garderobo-looks-similars-popup {
  height: 100%;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-item-swap-container {
  position: static;
  width: auto;
  height: 100%;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-swap-list-item {
  border: none;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-item-swap-container-list {
  overflow: visible;
  height: 100%;
  background-color: transparent;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-item-swap-container-item:first-child {
  margin: 0;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-item-swap-container-item {
  box-sizing: border-box;
  width: 20%;
  height: 100%;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-item-swap-item-img-wrapper {
  width: 100%;
  height: 100%;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-item-swap-item-img {
  max-height: 100px;
  transition: ease-in 0.1s;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-item-swap-item-img:hover {
  transform: scale(1.08);
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-item-container {
  border-top: none;
  border-bottom: 1px solid #d7d7d7;
  display: flex;
  flex-direction: column;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-item-swap-item-price {
  position: static;
  margin-top: 5px;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-item {
  border-right: 1px solid #d7d7d7;
  width: 100%;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-header {
  display: flex;
  height: 60px;
  padding: 0;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-content {
  height: calc(100% - 60px - 1px);
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-content {
  height: 100%;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-subheader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  text-transform: uppercase;
  font-weight: bold;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-subheader:first-child {
  width: 100%;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-subheader:nth-child(2) {
  display: none;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-container {
  width: 100%;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-swap-list-item {
  width: 100%;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-item-img-wrapper {
  height: 100%;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-item-img {
  max-height: 100px;
}
.garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup__preloader {
  position: absolute;
  top: 61px;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}
@media screen and (min-width: 768px) {
  .garderobo-widget-popup.garderobo-looks-similars-popup {
    height: 80vh;
  }
  .garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-subheader:first-child {
    width: 50%;
    border-right: 1px solid #d7d7d7;
  }
  .garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-subheader:nth-child(2) {
    display: flex;
  }
  .garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-item {
    width: 50%;
  }
  .garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-swap-list-item {
    width: 50%;
  }
  .garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup-list-item-container {
    flex-direction: row;
  }
  .garderobo-widget-popup.garderobo-looks-similars-popup .garderobo-widget-popup__preloader {
    width: 50%;
  }
  .garderobo-widget-popup-2 {
    height: 80% !important;
  }
  .garderobo-widget-popup-2__header {
    margin: 0 0 40px;
  }
  .garderobo-widget-popup-2__left-block {
    width: 240px;
    display: block;
  }
  .garderobo-widget-popup-2__left-block-content {
    padding-right: 20px;
    height: calc(100% - 2px - 2 * 7px - 2 * 10px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .garderobo-widget-popup-2__left-block-line {
    margin: 0;
    border: none;
    border-top: 1px solid #eee;
    width: calc(100% - 20px);
  }
  .garderobo-widget-popup-2__right-block {
    display: block;
    padding-left: 45px;
    width: calc(100% - 240px);
  }
  .garderobo-widget-popup-2__right-blocks {
    display: none;
  }
  .garderobo-widget-popup-2__right-thumb {
    display: none;
  }
  .garderobo-widget-popup-2__right-similars {
    width: 100%;
  }
  .garderobo-widget-popup-2__mob-controls {
    display: none;
  }
  .garderobo-widget-popup-2__content {
    padding: 40px;
  }
  .garderobo-widget-popup-2__right-btn-buy {
    width: 400px;
  }
}
@media screen and (min-width: 1024px) {
  .garderobo-widget-popup-2__right-sim {
    width: calc((100% - 4 * 10px) / 5);
  }
}
/* ============================================================> looks popup */
.garderobo-looks-simple-popup {
  max-width: 600px;
  margin: 0 auto;
  height: 100%;
  max-height: unset;
}
.garderobo-looks-simple-popup .garderobo-widget-popup-list-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-weight: bold;
}
.garderobo-looks-simple-popup .garderobo-widget-popup-list-container {
  width: 100%;
}
.garderobo-looks-simple-popup .garderobo-widget-popup-list-item-pic {
  width: 20%;
  min-width: 100px;
  margin: 0;
  padding: 0 20px;
}
.garderobo-looks-simple-popup .garderobo-widget-popup-list-item-text {
  width: 80%;
  margin: 0;
  padding-right: 30px;
}
.garderobo-looks-simple-popup .garderobo-widget-popup-list-item-img-wrapper {
  height: unset;
}
.garderobo-looks-simple-popup .garderobo-widget-popup-list-item-img-v2 {
  width: 100%;
  height: auto;
}
.garderobo-looks-simple-popup .garderobo-widget-popup-list-item-text-title {
  margin-top: 0;
}
.garderobo-looks-simple-popup .garderobo-widget-popup-list-item-swap-container-item {
  box-sizing: border-box;
  height: calc(100% - 5px);
}
.garderobo-looks-simple-popup .garderobo-widget-popup-list-item-swap-item-img {
  width: auto;
  height: 100%;
  max-width: unset;
  max-height: unset;
}
@media screen and (min-width: 768px) {
  .garderobo-looks-simple-popup {
    height: 700px;
    max-height: calc(100vh - 60px - 1px);
  }
}
/* =======================================================> scroll-widget */
.garderobo-widget-container .garderobo-widget-feed.garderobo-widget-scroll .garderobo-widget-feed-container {
  overflow: unset;
}
.garderobo-widget-container .garderobo-widget-feed.garderobo-widget-scroll .garderobo-widget-feed-container .garderobo-widget-feed-items {
  display: block;
  grid-area: content / content / content / content;
  grid-template-columns: 1fr 3fr;
  gap: 6.66666667px;
  align-items: unset;
}
.garderobo-widget-container .garderobo-widget-feed.garderobo-widget-scroll .garderobo-widget-feed-container .garderobo-widget-feed-item .garderobo-widget-product-footer .garderobo-widget-product-name {
  word-break: break-word;
}
.garderobo-widget-container .garderobo-widget-feed.garderobo-widget-scroll .garderobo-widget-feed-container .garderobo-widget-sizes-and-cart-btn {
  margin-top: 8px;
  width: 100%;
}
.garderobo-widget-container .garderobo-widget-feed.garderobo-widget-scroll .garderobo-widget-feed-container .garderobo-widget-sizes {
  margin-bottom: 8px;
  padding: 5px;
  font-size: 12px;
}
.garderobo-widget-container .garderobo-widget-feed.garderobo-widget-scroll .garderobo-widget-feed-container .garderobo-widget-add-to-cart-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.garderobo-widget-scroll__main-block .garderobo-widget-feed-item,
.garderobo-widget-scroll__rest-block .garderobo-widget-feed-item {
  width: 100% !important;
}
.garderobo-widget-scroll__main-block {
  display: none;
  position: relative;
  padding-right: 13.33333333px;
}
.garderobo-widget-scroll__main-block .garderobo-widget-feed-item {
  position: sticky;
  top: 0;
}
.garderobo-widget-scroll__rest-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px 10px;
}
.garderobo-multiple-controls {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.garderobo-multiple-btn {
  padding: 10px 15px;
  border: 1px solid grey;
}
.garderobo-multiple-btn:hover {
  background-color: #eee;
}
@media screen and (min-width: 540px) {
  .garderobo-widget-scroll__rest-block {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px 10px;
  }
}
@media screen and (min-width: 768px) {
  .garderobo-widget-container .garderobo-widget-feed.garderobo-widget-scroll .garderobo-widget-feed-container .garderobo-widget-sizes {
    font-size: 16px;
  }
  .garderobo-widget-scroll__rest-block {
    gap: 30px 20px;
  }
}
@media screen and (min-width: 960px) {
  .garderobo-widget-container .garderobo-widget-feed.garderobo-widget-scroll .garderobo-widget-feed-container .garderobo-widget-feed-items {
    display: grid;
  }
  .garderobo-widget-scroll__main-block {
    display: block;
  }
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #333 transparent #333 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.garderobo-widget-popup-list-item-name {
  display: none;
}
/************ GRID LOOK STYLES *************/
.garderobo-grid-look-2 {
  display: grid;
  grid-template-rows: repeat(40, 2.5%) !important;
  grid-template-columns: repeat(40, 2.5%) !important;
}
.garderobo-grid-look {
  display: grid;
  grid-template-rows: repeat(20, 5%);
  grid-template-columns: repeat(20, 5%);
}
.garderobo-grid-look__product_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.garderobo-grid-look__accessory {
  grid-row: 11 / 13;
  grid-column: 18 / 20;
}
.garderobo-grid-look__accessory + .garderobo-grid-look__accessory {
  grid-row: 3 / 5;
  grid-column: 10 / 12;
}
.garderobo-grid-look__gloves_head {
  grid-row: 11 / 15;
  grid-column: 2 / 5;
}
.garderobo-grid-look__layer-top-left {
  grid-row: 3 / 11;
  grid-column: 3 / 11;
  z-index: 4;
}
.garderobo-grid-look__gloves_glass {
  grid-row: 12 / 15;
  grid-column: 15 / 19;
  z-index: 5;
}
.garderobo-grid-look__layer-top-right {
  grid-row: 3 / 11;
  grid-column: 11 / 19;
}
.garderobo-grid-look__layer-middle-left {
  grid-row: 8 / 15;
  grid-column: 1 / 9;
  z-index: 3;
}
.garderobo-grid-look__bottom {
  grid-row: 11 / 21;
  grid-column: 7 / 15;
  z-index: 2;
}
.garderobo-grid-look__bottom img {
  object-position: top;
}
.garderobo-grid-look__bottom_skirt {
  grid-row: 11 / 19;
  grid-column: 7 / 15;
  z-index: 2;
  padding-inline: 10px;
}
.garderobo-grid-look__bottom_skirt img {
  object-position: top;
}
.garderobo-grid-look__layer-full {
  grid-row: 8 / 20;
  grid-column: 7 / 15;
  padding-inline: 8px;
  z-index: 4;
}
.garderobo-grid-look__layer-full img {
  object-position: top;
  transform: scale(0.9);
}
.garderobo-grid-look__bottom_shorts {
  grid-row: 11 / 17;
  grid-column: 7 / 15;
  padding-inline: 8px;
  z-index: 2;
}
.garderobo-grid-look__bottom_shorts img {
  object-position: top;
}
.garderobo-grid-look__shoes {
  grid-row: 16 / 21;
  grid-column: 2 / 7;
  transform: scaleX(-1);
}
.garderobo-grid-look__shoes img {
  object-position: bottom;
}
.garderobo-grid-look__gloves {
  grid-row: 11 / 13;
  grid-column: 18 / 20;
}
.garderobo-grid-look__bag {
  grid-row: 15 / 19;
  grid-column: 15 / 19;
}
.garderobo-grid-look__bag img {
  object-position: bottom;
}
.garderobo-grid-look__socks {
  grid-row: 13 / 17;
  grid-column: 1 / 4;
  z-index: 4;
}
.garderobo-grid-look__belt {
  grid-row: 12 / 14;
  grid-column: 15 / 18;
  z-index: 4;
}
.garderobo-grid-look__hat {
  grid-row: 11 / 15;
  grid-column: 2 / 5;
}
.garderobo-grid-look__gloves_scarf {
  grid-row: 12 / 17;
  grid-column: 1 / 5;
}
.garderobo-grid-look [data-category-collage="layer-2_vest"] img {
  transform: scale(0.9);
}
.garderobo-grid-look.garderobo-grid-look__layer_count_0 .garderobo-grid-look__gloves_head ~ .garderobo-grid-look__gloves_head {
  grid-row: 10 / 14;
  grid-column: 16 / 19;
}
.garderobo-grid-look.garderobo-grid-look__layer_count_0 .garderobo-grid-look__layer-full {
  grid-row: 7 / 20;
  grid-column: 6 / 15;
  padding-inline: 12px;
}
.garderobo-grid-look.garderobo-grid-look__layer_count_0 .garderobo-grid-look__bag {
  grid-row: 15 / 20;
  grid-column: 16 / 20;
}
.garderobo-grid-look.garderobo-grid-look__layer_count_0 .garderobo-grid-look__gloves_glass,
.garderobo-grid-look.garderobo-grid-look__layer_count_0 .garderobo-grid-look__gloves_head ~ .garderobo-grid-look__gloves_glass {
  grid-row: 11 / 15;
  grid-column: 16 / 19;
}
.garderobo-grid-look.garderobo-grid-look__layer_count_1 .garderobo-grid-look__layer-top-left {
  grid-row: 3 / 11;
  grid-column: 11 / 19;
  z-index: 4;
}
.garderobo-grid-look.garderobo-grid-look__layer_count_1 .garderobo-grid-look__layer-full {
  grid-row: 7 / 20;
  grid-column: 6 / 15;
  padding-inline: 12px;
}
.garderobo-grid-look.garderobo-grid-look__layer_count_1 .garderobo-grid-look__bag {
  grid-row: 15 / 20;
  grid-column: 16 / 20;
}
.garderobo-grid-look.garderobo-grid-look__layer_count_3 .garderobo-grid-look__layer-top-left {
  grid-row: 4 / 12;
  grid-column: 1 / 9;
  z-index: 5;
}
.garderobo-grid-look.garderobo-grid-look__layer_count_3 .garderobo-grid-look__layer-middle-left {
  grid-row: 2 / 10;
  grid-column: 7 / 14;
  z-index: 4;
}
.garderobo-grid-look.garderobo-grid-look__layer_count_3 .garderobo-grid-look__layer-top-right {
  grid-row: 4 / 12;
  grid-column: 12 / 20;
  z-index: 4;
}
.garderobo-grid-look.garderobo-grid-look__layer_count_3 .garderobo-grid-look__gloves_head {
  grid-row: 12 / 16;
  grid-column: 2 / 5;
}
.garderobo-grid-look__gloves,
.garderobo-grid-look__socks {
  display: none;
}
.garderobo-shopthemodellook .garderobo-widget-feed-container {
  overflow: initial !important;
}
.garderobo-shopthemodellook .garderobo-widget-feed-items {
  justify-content: flex-start !important;
  gap: 47px 8.19%;
}
@media screen and (max-width: 1200px) {
  .garderobo-shopthemodellook .garderobo-widget-feed-items {
    column-gap: 30px;
  }
}
@media screen and (max-width: 992px) {
  .garderobo-shopthemodellook .garderobo-widget-feed-items {
    flex-direction: column;
  }
}
@media screen and (max-width: 576px) {
  .garderobo-shopthemodellook .garderobo-widget-feed-items {
    row-gap: 25px;
  }
}
.garderobo-shopthemodellook img {
  max-width: 100%;
}
.garderobo-shopthemodellook__image {
  position: sticky;
  top: 0;
}
.garderobo-shopthemodellook__main-block {
  width: 30.5%;
}
@media screen and (max-width: 992px) {
  .garderobo-shopthemodellook__main-block {
    max-width: 472px;
    width: 92%;
  }
}
@media screen and (max-width: 576px) {
  .garderobo-shopthemodellook__main-block {
    max-width: 100%;
    width: 100%;
  }
}
.garderobo-shopthemodellook__rest-block {
  display: grid;
  grid-template-columns: repeat(3, minmax(220px, 1fr));
  gap: 64px 4.51%;
  width: 68%;
}
@media screen and (max-width: 1200px) {
  .garderobo-shopthemodellook__rest-block {
    column-gap: 30px;
  }
}
@media screen and (max-width: 992px) {
  .garderobo-shopthemodellook__rest-block {
    grid-template-columns: repeat(3, minmax(calc(33.333% - 20px), 1fr));
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .garderobo-shopthemodellook__rest-block {
    grid-template-columns: repeat(2, minmax(calc(50% - 12.5px), 1fr));
    gap: 32px 25px;
  }
}
.garderobo-shopthemodellook__sizes_wrap {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 12px;
  padding-inline: 12px;
  border: 1px solid #C7C7C7;
  z-index: 9;
}
.garderobo-shopthemodellook__sizes_wrap:not(.garderobo-disabled-select):hover {
  border-color: #000;
  cursor: pointer;
}
.garderobo-shopthemodellook__sizes_wrap:not(.garderobo-disabled-select):hover .garderobo-widget-sizes {
  border-color: #000;
  cursor: pointer;
}
@media screen and (max-width: 576px) {
  .garderobo-shopthemodellook__sizes_wrap {
    height: 42px;
  }
}
.garderobo-shopthemodellook__sizes_text {
  font-family: 'HelveticaNeueCyr';
  font-size: 12px;
  line-height: 30px;
  font-weight: 550;
  letter-spacing: 0.1em;
  color: #808080;
}
@media screen and (max-width: 576px) {
  .garderobo-shopthemodellook__sizes_text {
    font-size: 10px;
  }
}
.garderobo-shopthemodellook .garderobo-widget-sizes-and-cart-btn {
  width: 100%;
  margin-top: 38px;
}
@media screen and (max-width: 576px) {
  .garderobo-shopthemodellook .garderobo-widget-sizes-and-cart-btn {
    margin-top: 8px;
  }
}
.garderobo-shopthemodellook .garderobo-widget-add-to-cart-btn {
  width: 100%;
  height: 64px;
  font-size: 14px;
  line-height: 30px;
  font-weight: 550;
  color: #fff;
  letter-spacing: 0.1em;
  background-color: #000;
  text-transform: uppercase;
  text-align: center;
}
@media screen and (max-width: 576px) {
  .garderobo-shopthemodellook .garderobo-widget-add-to-cart-btn {
    height: 52px;
    font-size: 10px;
  }
}
.garderobo-shopthemodellook .garderobo-widget-add-to-cart-btn:not(.disabled):hover {
  opacity: 0.8;
}
.garderobo-shopthemodellook__rest-block .garderobo-widget-feed-item {
  width: 100%;
}
/* Custom Select */
.garderobo-custom-select {
  width: 100%;
}
.garderobo-custom-selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  height: 28px;
}
.garderobo-custom-select-icon {
  width: 18px;
  height: 18px;
  transform: rotate(180deg);
}
.garderobo-shopthemodellook__sizes_wrap .garderobo-widget-sizes {
  position: absolute;
  top: 54px;
  left: 0;
  display: none;
  flex-direction: column;
  width: 100%;
  padding: 0 10px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.01em;
  background-color: #fff;
  border-left: 1px solid #C7C7C7;
  border-right: 1px solid #C7C7C7;
  border-bottom: 1px solid #C7C7C7;
  box-sizing: border-box;
}
.garderobo-custom-select-list span {
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  color: #000;
  cursor: pointer;
}
.garderobo-custom-select-list span.active {
  font-weight: 700;
  cursor: default;
}
.garderobo-custom-select-list span:not(.active):hover {
  color: #808080;
}
.opened-size-menu .garderobo-widget-sizes {
  display: flex;
}
.garderobo-scroll-button {
  position: absolute;
  left: 10px;
  top: 10px;
}
