.garderobo-creator {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  max-width: 1280px;
  margin: 0 auto;
  overflow: hidden;
  margin-bottom: 40px;
  clear: both;
  color: #333;
}
.garderobo-creator-header {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin: 0 0 40px 0;
}
.garderobo-creator-products-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px;
  margin-bottom: 30px;
  padding: 0 15px;
}
.garderobo-creator-product {
  display: block;
  position: relative;
  box-sizing: border-box;
  border: 1px solid #ccc;
  color: #333;
  height: 180px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin-bottom: 5px;
}
.garderobo-creator-product--add-new {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.garderobo-creator-product--new {
  padding: 20px;
}
.garderobo-creator-product--main {
  border-color: tomato;
  border-width: 2px;
}
.garderobo-creator-product .garderobo-creator-input-text {
  width: 100%;
  margin-bottom: 10px;
}
.garderobo-creator-product .garderobo-creator-btn {
  width: 100%;
}
.garderobo-creator-product__name {
  margin-bottom: 5px;
}
.garderobo-creator-product__name-link {
  text-decoration: none;
  color: inherit;
}
.garderobo-creator-product__name-link:hover {
  color: blue;
}
.garderobo-creator-product__price {
  font-size: 18px;
}
.garderobo-creator-product__btn-left,
.garderobo-creator-product__btn-right,
.garderobo-creator-product__btn-delete,
.garderobo-creator-product__btn-refresh {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
}
.garderobo-creator-product__btn-left:hover,
.garderobo-creator-product__btn-right:hover,
.garderobo-creator-product__btn-delete:hover,
.garderobo-creator-product__btn-refresh:hover {
  background-color: rgba(220, 220, 220, 0.8);
}
.garderobo-creator-product__btn-delete {
  top: -15px;
  left: -15px;
  font-size: 30px;
}
.garderobo-creator-product__btn-refresh {
  top: -15px;
  right: -15px;
  font-size: 30px;
}
.garderobo-creator-product__btn-refresh::after {
  content: '\27F3';
  transform: translate(1px, -1px);
}
.garderobo-creator-product__btn-left,
.garderobo-creator-product__btn-right {
  bottom: 5px;
  font-size: 20px;
}
.garderobo-creator-product__btn-left::after,
.garderobo-creator-product__btn-right::after {
  content: '\1433';
  transform: rotateY(45deg);
}
.garderobo-creator-product__btn-left {
  transform: rotate(-180deg);
  left: 5px;
}
.garderobo-creator-product__btn-right {
  right: 5px;
}
.garderobo-creator-product__btn-new {
  position: relative;
  width: 100px;
  height: 100px;
  background-size: 10px 10px;
  background-image: repeating-linear-gradient(45deg, #dddddd 0px, #dddddd 1px, #ffffff 0px, #ffffff 50%);
}
.garderobo-creator-product__btn-new::after {
  content: '';
  position: absolute;
  width: 1px;
  background-color: #333;
  top: 0;
  bottom: 0;
}
.garderobo-creator-product__btn-new::before {
  content: '';
  position: absolute;
  height: 1px;
  background-color: #333;
  left: 0;
  right: 0;
}
.garderobo-creator-bottom-controls-wrapper {
  padding: 20px 0;
  box-shadow: 0 -2px 7px -5px;
  border-bottom: 1px solid #ddd;
}
.garderobo-creator-bottom-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 700px;
  padding: 0 15px;
  margin: 0 auto;
}
.garderobo-creator-bottom-controls .garderobo-creator-btn {
  width: 100%;
  margin-bottom: 10px;
}
.garderobo-creator-btn {
  border: 1px solid #ddd;
  padding: 10px 20px;
  font-size: 14px;
}
.garderobo-creator-btn:hover {
  background-color: #f5f5f5;
}
.garderobo-creator-input-text {
  border: 1px solid #ddd;
  padding: 10px 20px;
  font-size: 14px;
  box-sizing: border-box;
}
.garderobo-creator-btn-link {
  font-size: 14px;
  width: 50%;
  margin-bottom: 20px;
}
.garderobo-creator-btn-link:hover {
  color: blue;
}
.garderobo-creator-btn-link--prev::before {
  content: '< ';
}
.garderobo-creator-btn-link--next::after {
  content: ' >';
}
@media screen and (min-width: 425px) {
  .garderobo-creator-product {
    height: 300px;
  }
  .garderobo-creator-bottom-controls .garderobo-creator-btn {
    width: calc(33.3% - 10px);
    margin: 0 5px 5px;
  }
}
@media screen and (min-width: 768px) {
  .garderobo-creator-products-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .garderobo-creator-bottom-controls .garderobo-creator-btn {
    width: 120px;
  }
}
@media screen and (min-width: 1024px) {
  .garderobo-creator-products-container {
    grid-template-columns: repeat(5, 1fr);
  }
}
